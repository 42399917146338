// extracted by mini-css-extract-plugin
export var active = "Styles-module--active--e1000";
export var activePoint = "Styles-module--activePoint--c471f";
export var blockDescription = "Styles-module--blockDescription--35a87";
export var buttons = "Styles-module--buttons--fb4f7";
export var cont = "Styles-module--cont--e88f6";
export var counter = "Styles-module--counter--8f528";
export var leftSide = "Styles-module--leftSide--3e198";
export var paragraph = "Styles-module--paragraph--a3c6c";
export var point = "Styles-module--point--3fcc5";
export var reverze = "Styles-module--reverze--c6cf4";
export var small = "Styles-module--small--769a9";