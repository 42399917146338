// extracted by mini-css-extract-plugin
export var arrow = "Styles-module--arrow--85808";
export var btnInner = "Styles-module--btnInner--6e233";
export var header = "Styles-module--header--aab49";
export var icon = "Styles-module--icon--5f7b2";
export var itemContent = "Styles-module--itemContent--458ae";
export var leftSide = "Styles-module--leftSide--d73b0";
export var num = "Styles-module--num--b4596";
export var odd = "Styles-module--odd--c2e72";
export var section = "Styles-module--section--87200";
export var sectionContainer = "Styles-module--sectionContainer--74a0d";
export var sideMenuFixer = "Styles-module--sideMenuFixer--60157";